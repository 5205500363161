@import "~antd/dist/antd.css";

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout.ant-layout-has-sider {
  height: 100%;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .logo-img {
    height: 20px;
    width: 20px;
    vertical-align: sub;
  }
  .sys-name {
    margin-left: 10px;
  }
}

.site-layout .site-layout-background {
  background: #fff;
}

.search-result-list {
  height: 15px;
}
